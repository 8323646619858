import './search.scss';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
// import { getParent } from '../../js/helper';

class Search {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-search',
            fieldAttr: 'data-input',
            rootToggleAttr: 'root-toggle',
            navInitAttr: 'data-nav',
            navRootToggleAttr: 'root-toggle',
            navToggleAttr: 'toggle',
            eventScroller: null
        };

        this.settings = Object.assign({}, defaults, options);

        this.$search = element;
        this.$header = document.querySelector('[data-header="root"]');
        this.$rootToggle = document.querySelector('[' + this.settings.initAttr + '="' + this.settings.rootToggleAttr + '"]');
        this.$navigationRootToggle = this.$header.querySelector('[' + this.settings.navInitAttr + '="' + this.settings.navRootToggleAttr + '"]');
        this.$navigationToggles = this.$header.querySelectorAll('[' + this.settings.navInitAttr + '="' + this.settings.navToggleAttr + '"]');
        this.searchOpen = false;

        if (this.$search.querySelector('form') === null) {
            console.error('no form found');
            return;
        }

        this.initialize();
    }

    initialize () {
        this.initFormFields();
        this.setEvents();

        this.breakpoint = window.matchMedia('(min-width:1024px)');
    }

    initFormFields () {
        const $fields = this.$search.querySelectorAll('[' + this.settings.fieldAttr + ']');

        if ($fields.length > 0) {
            import('../form/form-input').then(({ FormInput }) => {
                for (let i = 0; i < $fields.length; i++) {
                    const $field = $fields[i];
                    const formAPI = new FormInput($field);
                    $field.API = formAPI;
                }
            });
        }
    }

    setEvents () {
        if (this.$rootToggle) {
            this.$rootToggle.addEventListener('change', () => {
                if (this.$rootToggle.checked === true) {
                    this.searchOpen = true;
                    // if navigation is open, close navigation
                    if (this.$navigationRootToggle && this.$navigationRootToggle.checked === true) {
                        this.$navigationRootToggle.checked = false;
                        const toggleEvent = new Event('change');
                        this.$navigationRootToggle.dispatchEvent(toggleEvent);
                    }
                    if (this.$header) {
                        this.$header.classList.add('show--always');
                    }
                    if (!window.Modernizr.csshover) {
                        disableBodyScroll(this.$search);
                    }
                    for (let i = 0; i < this.$navigationToggles.length; i++) {
                        this.$navigationToggles[i].checked = false;
                    }
                } else {
                    this.searchOpen = false;
                    if (this.$header) {
                        this.$header.classList.remove('show--always');
                    }
                    if (!window.Modernizr.csshover) {
                        enableBodyScroll(this.$search);
                    }
                }
            });

            const eventScroller = this.settings.eventScroller;
            if (eventScroller !== null) {
                let scrolling = false;
                let lastPosition = 0;
                eventScroller.customFunctions.push(() => {
                    if (scrolling === false) {
                        lastPosition = eventScroller.getScrollInfo().position;
                    }
                    if (this.$rootToggle.checked === true) {
                        const scrollInfo = eventScroller.getScrollInfo();
                        const position = scrollInfo.position;
                        scrolling = true;

                        if (position > lastPosition + 2 * window.innerHeight || position >= document.body.offsetHeight) {
                            this.$rootToggle.checked = false;
                            const toggleEvent = new Event('change');
                            this.$rootToggle.dispatchEvent(toggleEvent);
                        }
                    }
                });
            }

            /*
            document.addEventListener('click', (e) => {
                const $clickedElement = e.target;

                if (this.searchOpen === true && this.breakpoint.matches === true && $clickedElement.tagName.toLowerCase() !== 'label') {
                    const $parent = getParent($clickedElement, '.siteheader__search-inner');

                    if ($parent === null) {
                        this.$rootToggle.checked = false;
                        const toggleEvent = new Event('change');
                        this.$rootToggle.dispatchEvent(toggleEvent);
                    }
                }
            });
            */
        }
    }
}

export { Search };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    console.log($context);

    if ($context) {
        const $search = $context.querySelector('[data-search="root"]');

        if ($search) {
            const searchAPI = new Search($search, {
                eventScroller: window.eventScroller
            });
            $search.searchAPI = searchAPI;
        }
    }
});
